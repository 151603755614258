<template>
<div>
    <nh-title title="区域管理"></nh-title>
    <div style="display: flex">
        <div><nh-menu active-selected="AreaList"></nh-menu></div>
        <div class="list-container">
            <div class="search-bar-wrapper">
                <div class="search-bar">

                </div>
                <div class="add-button">
                    <img src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/de5107bbc0efbd55bd7d5adc68714391.png" @click="doAdd"/>
                </div>
            </div>
            <el-table class="list-table-container"
                    :data="areaList"
                    style="width: 100%">
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="dmName" label="DM"></el-table-column>
                <el-table-column prop="bigAreaName" label="大区"></el-table-column>
                <el-table-column prop="dmPhone" label="电话"></el-table-column>
                <el-table-column prop="dmCity" label="工作城市"></el-table-column>
                <el-table-column prop="hospitalList.length" label="驻点医院"></el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="scope">
                        <div class="area-operate">
                            <img @click="doEdit(scope.row)" src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/6b5b5024d7b72e164f57e27f9d184938.png"/>
                            <img @click="doDelete(scope.row)" src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/73959836d868a7e73aa685310c8aa7cd.png"/>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import NhMenu from "@/components/patient/components/nh-menu";
export default {
    name: "AreaList",
    components: {NhMenu, NhTitle},
    data() {
        return {
            areaList: []
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$httpUtil.get(this.$urlConstant.nuohua.areaList,res=>{
                this.areaList = res.data
            },this)
        },
        doAdd() {
            this.$router.push({name: 'AreaEdit'})
        },
        doEdit(area) {
            this.$router.push({name: 'AreaEdit', query:{id:area.id}})
        },
        doDelete(area) {
            this.$confirm('确认删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$httpUtil.delete(this.$urlConstant.nuohua.areaGetPutDel + area.id,()=>{
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.getData()
                    }, 1000);
                }, this)
            }).catch(() => {

            });
        }
    },
}
</script>

<style scoped>
.search-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
}
.search-bar {
    width: 220px;
    margin-left: 5px;
}

.area-operate {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.area-operate img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    object-fit: contain;
}
</style>